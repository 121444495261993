import React, { useState } from "react";
import ContactCTAForm from "./../ContactCTAForm";
import contactCTA from "./../../util/contactCTA.js";
import "./styles.scss";

function ContactCTA(props) {
  const [status, setStatus] = useState();

  const onSubmit = ({ name, email, message }) => {
    setStatus({ type: "pending" });

    contactCTA.submit({ name, email, message }).then(() => {
      setStatus({
        type: "success",
        message: "Your message has been sent! We'll be in touch shortly."
      });
    });
  };
  return (
    <ContactCTAForm
      parentColor={props.parentColor}
      showNameField={props.showNameField}
      buttonText={props.buttonText}
      onSubmit={onSubmit}
      status={status}
    />
  );
}

export default ContactCTA;
