import React from "react";
import HeroSection from "./../../components/HeroSection";
import ClientsSection from "./../../components/ClientsSection";
import FeaturesSection from "./../../components/FeaturesSection";
import NewsletterSection from "./../../components/NewsletterSection";
import { useRouter } from "./../../util/router.js";
import "./styles.scss";

function HomePage(props) {
  const router = useRouter();

  return (
    <>
      <HeroSection
        color="white"
        size="medium"
        title="Expert DevOps Engineering"
        subtitle="Fifth Insight is a DevOps & Automation Consultancy based in the US focused on Improving & Managing Production Grade Infrastructure and Deployments for Enterprise Businesses in the Cloud"
        image="https://uploads.divjoy.com/undraw-japan_ubgk.svg"
        buttonText="Get In Touch"
      />
      <ClientsSection
        color="light"
        size="normal"
        title=""
        subtitle=""
        clients={[
          {
            name: "Kubernetes",
            image: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/kubernetes_logo_with_border.svg",
            url: "https://kubernetes.io/",
            width: "90px"
          },
          {
            name: "AWS",
            image: "https://upload.wikimedia.org/wikipedia/commons/9/93/Amazon_Web_Services_Logo.svg",
            url: "https://aws.amazon.com/",
            width: "90px",
            marginTop: "20px"
          },
          {
            name: "Terraform",
            image: "https://www.datocms-assets.com/2885/1620155116-brandhcterraformverticalcolor.svg",
            url: "https://terraform.io",
            width: "90px"
          },
          {
            name: "Serverless",
            image: "https://avatars1.githubusercontent.com/u/13742415",
            url: "https://serverless.com",
            width: "80px"
          },
          {
            name: "Python",
            image: "https://upload.wikimedia.org/wikipedia/commons/c/c3/Python-logo-notext.svg",
            url: "https://python.org/",
            width: "80px"
          },
          {
            name: "Golang",
            image: "https://camo.githubusercontent.com/98ed65187a84ecf897273d9fa18118ce45845057/68747470733a2f2f7261772e6769746875622e636f6d2f676f6c616e672d73616d706c65732f676f706865722d766563746f722f6d61737465722f676f706865722e706e67",
            url: "https://golang.org/",
            width: "60px"
          },
          {
            name: "Datadog",
            image: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/dd_icon_rgb.svg",
            url: "https://datadog.com",
            width: "80px"
          }
        ]}
      />
      <FeaturesSection
        color="white"
        size="medium"
        title="Core Services"
        subtitle="Best in class DevOps Engineers to improve your software distribution pipelines: Migrations to AWS, Docker, Kubernetes, or Serverless meeting all security and compliance regulations."
        features={[
          {
            title: "Advisory",
            description: "Infrastructure is blocking the growth of your organization. Use our wealth of experience to tailor a solution that dovetails with your organization and priorities.",
            image: "https://uploads.divjoy.com/undraw-mind_map_cwng.svg"
          },
          {
            title: "Development",
            description: "You know what you need from your infrastructure and just need to accelerate the process. We are practiced and able. We’ll get you there in half the time you expect.",
            image: "https://uploads.divjoy.com/undraw-having_fun_iais.svg"
          },
          {
            title: "Management",
            description: "Your infrastructure is in place, and you need a trustworthy group to manage it. We will keep your infrastructure and developers in front of the curve - and not behind in - to your maximum advantage.",
            image: "https://divjoy.com/static/images/uploads/undraw-balloons_vxx5.svg"
          },
          {
            title: "Maintenance",
            description: "Your infrastructure is stable, and you want to keep it that way. You can sleep easy, knowing we never sleep.",
            image: "https://uploads.divjoy.com/undraw-personal_settings_kihd.svg"
          },
        ]}
      />
      <NewsletterSection
        color="primary"
        size="medium"
        title="Stay in touch"
        subtitle="Receive our latest service updates and announcements"
        buttonText="Subscribe"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

export default HomePage;
