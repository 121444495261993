import React, { useState } from "react";
import FormStatus from "./../FormStatus";
import FormField from "./../FormField";
import SectionButton from "./../SectionButton";
import "./styles.scss";

function ContactCTAForm(props) {
  // State for input values
  const [email, setEmail] = useState("");

  // Whether to show errors
  // We set to true if they submit and there are errors.
  // We only show errors after they submit because
  // it's annoying to see errors while typing.
  const [showErrors, setShowErrors] = useState(false);

  // Error array we'll populate
  let errors = [];

  // Function for fetching error for a field
  const getError = field => {
    return errors.find(e => e.field === field);
  };

  // Function to see if field is empty
  const isEmpty = val => val.trim() === "";

  // Add error if email empty
  if (isEmpty(email)) {
    errors.push({
      field: "email",
      message: "Please enter an email"
    });
  }

  // Handle form submission
  const handleSubmit = e => {
    // If field errors then show them
    if (errors.length) {
      setShowErrors(true);
    } else {
      // Otherwise call onSubmit with form data
      if (props.onSubmit) {
        props.onSubmit({
          email
        });
      }
    }
  };

  return (
    <>
      {props.status && props.status.message && (
        <FormStatus type={props.status.type} message={props.status.message} />
      )}

      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="columns is-vcentered">
          <div className="column is-2" />
          <div className="column is-6 has-text-centered">
            <FormField
                value={email}
                type="email"
                placeholder="Enter your email"
                error={showErrors && getError("email")}
                onChange={value => setEmail(value)}
              />
          </div>
          <div className="column is-2 has-text-centered">
              <SectionButton
                parentColor={props.parentColor}
                size="medium"
                state={
                  props.status && props.status.type === "pending"
                    ? "loading"
                    : "normal"
                }
              >
                {props.buttonText}
              </SectionButton>
          </div>
          <div className="column is-2" />
        </div>
      </form>
    </>
  );
}

export default ContactCTAForm;
