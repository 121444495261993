import React from "react";
import Section from "./../Section";
import SectionHeader from "./../SectionHeader";
import ContactCTA from "./../ContactCTA";
import SectionButton from "./../SectionButton";
import "./styles.scss";

function HeroSection(props) {
  return (
    <Section color={props.color} size={props.size}>
      <div className="container">
        <div className="columns is-vcentered is-desktop">
          <div className="column is-2" />
          <div className="column is-8-desktop has-text-centered">
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={1}
            />
            <ContactCTA
              buttonText={props.buttonText}>
            </ContactCTA>
          </div>
          <div className="column is-2" />
        </div>
        <div className="columns is-vcentered is-desktop">
          <div className="column">
            <figure className="HeroSection__image image">
              <img src={props.image} alt="Illustration" />
            </figure>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
