function submit(data) {
  return fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({ "form-name": "contactCTA", ...data })
  })
  .then(r => {return {"success": true}})
  .catch(err => {return {"success":false, "err": err}});

}

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

export default { submit };
