import React from "react";
import ContentSection from "./../../components/ContentSection";
import TeamBiosSection from "./../../components/TeamBiosSection";
import "./styles.scss";

function TeamPage(props) {
  return (
    <>
      <ContentSection
        color="primary"
        size="medium"
        title="Team"
        // subtitle="We're a collection of DevOps experts from AI/ML, Fintech, and Healthcare industries with large scale B2B, B2C experience across dozens of teams and customers."
      />
      <TeamBiosSection
        color="white"
        size="medium"
        // title="Meet the Team"
        subtitle=""
        people={[
          {
            avatar: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/people/chris-stobie-smaller.jpg",
            name: "Chris Stobie",
            role: "Founder, DevOps",
            bio: "Chris is a natural born leader and Cloud Guru living in Laguna Beach, CA focused on building teams and delivering mission critical solutions. Throughout his career, he's been given free reign to build out numerous DevOps teams repeatedly. He has also architected and implemented security & compliance frameworks for multiple businesses securing successful audits for FEDRamp High (NIST), HIPAA, HITrust, and SOC2. Chris can help scale out your systems, teams, and compliance frameworks."
          },
          {
            avatar: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/people/ryan-smith-smaller.jpg",
            name: "Ryan Smith",
            role: "Founder, DevOps",
            bio: "Ryan is a DevOps Architect and Open Source contributor living in Irvine, CA working with startups and enterprise clients alike. He focuses on 'simplicity first,' future-proof systems based on best practices of computer science over the past 70 years. He found his niche developing and improving DevOps tooling in Golang while being a technology idealist in AWS, Serverless systems & Kubernetes deployments.",
          },
          // {
          //   avatar: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/people/mary-sawaya-smaller.jpeg",
          //   name: "Mary Sawaya",
          //   role: "DevOps",
          //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
          //   twitterUrl: "https://twitter.com",
          //   facebookUrl: "https://facebook.com",
          //   linkedinUrl: "https://linkedin.com"
          // },
          // {
          //   avatar: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/people/david-ibrahim-smaller.jpeg",
          //   name: "David Ibrahim",
          //   role: "DevOps",
          //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
          //   twitterUrl: "https://twitter.com",
          //   facebookUrl: "https://facebook.com",
          //   linkedinUrl: "https://linkedin.com"
          // },
          // {
          //   avatar: "https://public-images--segami-cilbup.s3-us-west-2.amazonaws.com/people/matt-litchfield-smaller.jpeg",
          //   name: "Matt Litchfield",
          //   role: "DevOps",
          //   bio: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum consequatur numquam aliquam tenetur ad amet inventore hic beatae, quas accusantium perferendis sapiente explicabo, corporis totam! Labore reprehenderit beatae magnam animi!",
          //   twitterUrl: "https://twitter.com",
          //   facebookUrl: "https://facebook.com",
          //   linkedinUrl: "https://linkedin.com"
          // }
        ]}
      />
    </>
  );
}

export default TeamPage;
